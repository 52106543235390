'use client'
import { getNotificationsUnread } from '@/app/services/clientServices/getNotificationsUnread'
import { useQuery } from '@tanstack/react-query'
import { Image } from '@umahealth/occipital'
import { VisuallyHidden } from '@umahealth/occipital/client'
import Link from 'next/link'

/** Si tiene foto, foto, sino la letra del nombre en un circulo del color principal */
function HeaderProfileIcon({ fullname }: { fullname?: string }) {
	if (fullname) {
		return (
			<svg
				width="26"
				height="26"
				viewBox="0 0 26 26"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle cx="13" cy="13" r="13" fill="#315762" />
				<text
					x="50%"
					y="50%"
					textAnchor="middle"
					stroke="white"
					strokeWidth="1.2px"
					dy="6"
					className="font-light"
				>
					{' '}
					{fullname.charAt(0)}
				</text>
			</svg>
		)
	}

	return (
		<svg
			focusable={false}
			aria-hidden
			width="29"
			height="29"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle cx="32" cy="32" r="32" fill="#E0E0E0" />
			<path
				d="M32.4 13.7334C34.7693 13.7334 37.0415 14.6745 38.7169 16.3499C40.3922 18.0252 41.3334 20.2974 41.3334 22.6667C41.3334 25.036 40.3922 27.3082 38.7169 28.9835C37.0415 30.6588 34.7693 31.6 32.4 31.6C30.0308 31.6 27.7586 30.6588 26.0832 28.9835C24.4079 27.3082 23.4667 25.036 23.4667 22.6667C23.4667 20.2974 24.4079 18.0252 26.0832 16.3499C27.7586 14.6745 30.0308 13.7334 32.4 13.7334V13.7334ZM32.4 36.0667C42.2714 36.0667 50.2667 40.0644 50.2667 45V49.4667H14.5334V45C14.5334 40.0644 22.5287 36.0667 32.4 36.0667Z"
				fill="#828282"
			/>
		</svg>
	)
}

/** Sería lo que sale a la derecha en el Header del tipo Profile */
export default function HeaderProfile({
	profilePic,
	fullname,
}: {
	profilePic?: string
	fullname?: string
}) {
	const hasNotificationQuery = useQuery({
		queryKey: ['notificationsQuery'],
		queryFn: () => getNotificationsUnread(),
		staleTime: 1000 * 60 * 5 // 5 minutos
	})

	return (
		<div className="flex gap-2 w-full justify-end items-center">
			{ process.env.NEXT_PUBLIC_COUNTRY === 'AR' &&
				<Link
					href="/notification"
					data-test-id="notification-bell"
					className={`${hasNotificationQuery.data ? 'pending' : ''}`}
				>
					{hasNotificationQuery.data ? (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							fill="none"
						>
							<path
								className="fill-header-notifications"
								fillRule="evenodd"
								d="M8.75 17c.41421 0 .75.3358.75.75v.75c0 .5967.23705 1.169.659 1.591.422.4219.9943.659 1.591.659s1.169-.2371 1.591-.659c.4219-.422.659-.9943.659-1.591v-.75c0-.4142.3358-.75.75-.75s.75.3358.75.75v.75c0 .9946-.3951 1.9484-1.0984 2.6516-.7032.7033-1.657 1.0984-2.6516 1.0984-.9946 0-1.94839-.3951-2.65165-1.0984C8.39509 20.4484 8 19.4946 8 18.5v-.75c0-.4142.33579-.75.75-.75Z"
								clipRule="evenodd"
							/>
							<path
								className="fill-header-notifications"
								fillRule="evenodd"
								d="M12.0156 1c-1.4573 0-2.44109 1.14942-2.77654 2.27601l-.0049.0054c-.00638.00662-.01393.01301-.02146.0182-.00698.00482-.01118.0067-.01118.0067-.84864.34947-1.8479.9065-2.62888 1.92768C5.7855 6.26324 5.27407 7.69281 5.274 9.68828c-.00118 1.98802-.21145 3.07832-.52064 3.82682-.27109.6563-.62729 1.0878-1.13402 1.7015-.07407.0897-.15136.1834-.23207.2821-.94178 1.1526-.04491 2.7513 1.39126 2.7513H19.2573c1.4388 0 2.3206-1.6071 1.3865-2.7513-.0805-.0986-.1577-.192-.2317-.2816l-.0002-.0003c-.5068-.6138-.8629-1.0452-1.1338-1.7015-.1947-.4719-.3501-1.0796-.4383-1.9626-.4449.2309-.9386.3806-1.4616.4296.1041.8563.2761 1.5302.5134 2.1053.3627.8789.8695 1.4897 1.3723 2.0957l.0003.0004c.0729.0878.1458.1756.2181.2641.0469.0577.0507.1174.0203.1776-.0285.056-.0968.1246-.2453.1246H4.77853c-.15415 0-.22355-.0709-.25142-.1255-.01606-.0315-.02087-.062-.01856-.0876.00205-.0225.01044-.0526.0403-.0891.07237-.0886.14529-.1764.21828-.2644l.00019-.0002c.50275-.606 1.00945-1.2166 1.37243-2.0954.41724-1.0102.63305-2.3261.63425-4.39952 0-1.72759.4373-2.82019.99014-3.54305.55901-.73095 1.29164-1.15667 2.00809-1.45173.38187-.15707.76287-.50702.90047-.97589l.0006-.00187C10.8867 2.98152 11.4381 2.5 12.0156 2.5c.5774 0 1.129.48116 1.3452 1.2169l.0006.0019c.1368.46106.5137.81591.8999.97473.1009.04155.2022.08574.3033.13305.4849-.37266 1.0576-.6367 1.6818-.75569-.4771-.33779-.9664-.58009-1.4139-.76433 0 0-.0038-.00189-.0094-.00581-.0074-.00516-.0153-.0118-.0222-.01903-.0029-.00304-.0052-.00569-.0069-.00778C14.456 2.14947 13.4732 1 12.0156 1Z"
								clipRule="evenodd"
							/>
							<rect
								width="6"
								height="6"
								x="14"
								y="5"
								fill="#E54055"
								rx="3"
							/>
						</svg>
					) : (
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							fill="none"
						>
							<path
								className="fill-header-notifications"
								fillRule="evenodd"
								d="M9.22343 3.77601C9.55888 2.64942 10.5427 1.5 11.9999 1.5c1.4576 0 2.4405 1.14947 2.7785 2.27394.0017.00209.0039.00474.0069.00778.0069.00723.0147.01387.0222.01903.0056.00392.009.00561.0094.00581.8483.34926 1.8467.90715 2.6277 1.92858.7872 1.02964 1.2992 2.45899 1.2992 4.45316 0 1.9882.2098 3.0785.5187 3.827.2709.6563.627 1.0877 1.1338 1.7015.074.0897.1513.1832.2319.2819l.0002.0003c.9341 1.1441.0521 2.751-1.3867 2.751H4.7629c-1.43616 0-2.33304-1.5987-1.39126-2.7513.08071-.0987.158-.1924.23207-.2821.50673-.6137.86294-1.0452 1.13402-1.7015.30919-.7485.51946-1.8388.52064-3.8268m3.96506-6.41229c-.00129.00154-.00291.00335-.00489.0054-.00639.00662-.01393.01301-.02146.0182-.00699.00482-.01119.00669-.01118.0067l-.00046.00019c-.84864.34947-1.84744.90631-2.62842 1.92749-.78715 1.02925-1.29858 2.45839-1.29865 4.45381M11.9999 3c-.5774 0-1.1289.48152-1.3423 1.21574l-.0005.00187c-.1376.46887-.5186.81882-.90049.97589-.00006.00002-.00011.00005-.00017.00007-.71646.29506-1.44891.72071-2.00793 1.45166-.55283.72286-.99014 1.81546-.99014 3.54307v.0004c-.0012 2.0735-.21701 3.3889-.63425 4.3991-.36298.8788-.86968 1.4894-1.37242 2.0954-.07306.088-.14604.176-.21848.2646l-.00004.0001c-.02985.0365-.03821.0665-.04025.089-.00231.0256.0025.0561.01855.0876.02788.0546.09727.1255.25142.1255h14.4788c.1485 0 .2168-.0686.2452-.1246.0305-.0602.0267-.1199-.0203-.1776-.0724-.0886-.1453-.1765-.2183-.2645-.5028-.606-1.0096-1.2168-1.3723-2.0957-.417-1.0103-.6322-2.3259-.6322-4.3993 0-1.7254-.4376-2.8184-.9909-3.54211-.5595-.73185-1.2923-1.15833-2.0072-1.45266-.3862-.15882-.7631-.51367-.8999-.97473l-.0006-.0019C13.129 3.48116 12.5774 3 11.9999 3Z"
								clipRule="evenodd"
							/>
							<path
								className="fill-header-notifications"
								fillRule="evenodd"
								d="M9 17.25c.41421 0 .75.3358.75.75v.75c0 .5967.23705 1.169.659 1.591.422.4219.9943.659 1.591.659s1.169-.2371 1.591-.659c.4219-.422.659-.9943.659-1.591V18c0-.4142.3358-.75.75-.75s.75.3358.75.75v.75c0 .9946-.3951 1.9484-1.0984 2.6516C13.9484 22.1049 12.9946 22.5 12 22.5c-.9946 0-1.9484-.3951-2.65165-1.0984C8.64509 20.6984 8.25 19.7446 8.25 18.75V18c0-.4142.33579-.75.75-.75Z"
								clipRule="evenodd"
							/>
						</svg>
					)}
				</Link>
			}
			<Link href={'/profile'}>
				<VisuallyHidden>{'perfil'}</VisuallyHidden>
				<div>
					{profilePic ? (
						<Image
							src={profilePic}
							alt={''}
							height={'32px'}
							width={'32px'}
							rounded
							center
						/>
					) : (
						<HeaderProfileIcon fullname={fullname} />
					)}
				</div>
			</Link>
		</div>
	)
}
