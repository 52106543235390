'use client'
import { useRouter } from 'next/navigation'
import { IHeader } from './IHeader'
import HeaderLogo from './Header_Logo'
import Link from 'next/link'
import { IClientState } from '@/config/clients'

export default function LeftButton({action, type, to, client} : Pick<IHeader, 'action'|'type'|'to'>&{client?: IClientState}){
	
	const router = useRouter()

	if (action){
		return <a
			onClick={action}
			alt-text='Inicio'
			className='cursor-pointer'
		>
			<HeaderLogo client={client} type={type}/>
		</a>
	}

	if (!to && type === 'back'){
		return 	<a
			onClick={() => router.back()}
			alt-text='Retroceder'
			className='cursor-pointer'
		>
			<HeaderLogo client={client} type={type}/>
		</a>
	}

	return 	<Link
		href={to??'/'}
		alt-text={'Ir al inicio'}
		className='cursor-pointer'
	>
		<HeaderLogo client={client} type={type}/>
	</Link>

}