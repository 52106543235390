'use client'
import { notifications_client } from '@/config/endpoints'
import { getFirebaseIdToken } from '@umahealth/fe-firebase'

export const getNotificationsUnread = async () => {
	const bearerToken = await getFirebaseIdToken()
	const url = `${notifications_client}?status=unread&count=true`
	
	const response = await fetch(url, {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${bearerToken}`
		},
	})

	if (!response.ok){
		console.error('falló getNotificationsUnread', response.statusText)
		return null
	}

	const data = await response.json()
	return !!data?.count
}
