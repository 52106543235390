import { Combobox, ComboboxList, ComboboxOption } from 'occipital-new'
import { Input, Select, SelectItem } from '@umahealth/occipital/client'
import { Control, Controller, FieldError, FieldValues, Path, UseFormRegister, UseFormWatch } from 'react-hook-form'
import IFormRegister from './registerInputs'
import {countriesListWithLabel} from '@/config/countries'
import { matchSorter } from 'match-sorter'
import { checkValidDni, checkValidName, checkValidWs } from '@/components/Utils/validationInputs'
import { checkInvalidDate, validateUserAge } from '@/components/Utils/validationInputs'
import { dniOptions, pronounsOptions, sexOptions } from '@/components/Utils/selectOptions'
import { InputPhone } from '@umahealth/occipital/client'
import '@umahealth/occipital/styles'
import { cn } from '@/lib/utils'
import { InputHTMLAttributes } from 'react'

interface RegisterInputs {
	register: UseFormRegister<IFormRegister>
	watch: UseFormWatch<IFormRegister>
	error?: string
	fieldError?: FieldError
	isDependant?: boolean
}

/** Select no lleva register pero sí control */
interface RegisterSelect extends Omit<RegisterInputs,'register'> {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<IFormRegister, any>,
	defaultValue?: string
}

interface RegisterDNI extends RegisterInputs {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	control: Control<IFormRegister, any>;
}

interface IWsInput<T extends FieldValues> {
	control: Control<T>,
	register: UseFormRegister<T>,
	classNameContainer?: string,
	disabled?: boolean,
	error?: string,
	inputProps?: InputHTMLAttributes<HTMLInputElement>,
}

interface INationalityInput<T extends FieldValues> {
	register: UseFormRegister<T>,
	watch: UseFormWatch<T>
	fieldError?: FieldError
	classNameContainer?: string
}

function InputFirstname({register, error}: RegisterInputs){
	return (
		<Input
			inputMode='text'
			type='text'
			autoComplete='name'
			label='Nombre'
			placeholder='Nombre'
			{...register('firstname', {
				required: 'Debe introducir un nombre',
				validate: checkValidName
			})}
			error={error}
		/>
	)
}

function InputLastname({register, error}: RegisterInputs){
	return (
		<Input
			inputMode='text'
			type='text'
			autoComplete='family-name'
			label='Apellido'
			placeholder='Apellido'
			{...register('lastname', {
				required: 'Debe introducir un apellido',
				validate: checkValidName
			})}
			error={error}
		/>
	)
}

function InputChosenName({register, error}: RegisterInputs){
	return (
		<Input
			inputMode='text'
			type='text'
			autoComplete='given-name'
			label='Nombre'
			id='chosenName'
			placeholder='Nombre'
			{...register('chosenName', {
				required: 'Debe introducir un nombre escogido por usted',
				validate: checkValidName
			})}
			error={error}
		/>
	)
}

function InputDni({ register, watch, error, control }: RegisterDNI) {
	const selectedDocumentType = watch('documentType')

	const isDNI = selectedDocumentType === 'DNI' || !selectedDocumentType
	const isPassport = selectedDocumentType === 'Pasaporte'
	return (
		<>
			<Controller
				control={control}
				name='documentType'
				render={({field}) => (
					<Select
						label="Tipo de documento"
						placeholder='Tipo de documento'
						className='mt-6'
						defaultValue={selectedDocumentType ?? 'DNI'}
						onValueChange={(value) => field.onChange(value)}
						value={field.value}
						name={field.name}
						required
					>
						{dniOptions.map(({label, value}) => (
							<SelectItem value={value} key={value}>
								{label}
							</SelectItem>
						))}
					</Select>
				)}
			/>
			<Input
				inputMode={isDNI ? 'numeric' : 'text'}
				type={isDNI ? 'number' : 'text'}
				label="Número de documento"
				placeholder=''
				autoComplete="on"
				{...register('dni', {
					required: `Ingrese su número de ${selectedDocumentType}`,
					validate: value => checkValidDni(value, isDNI, isPassport),
					...(isDNI && {pattern:{
						value: /^[^.,\s]*$/, // Expresión regular para prohibir puntos, comas y espacios
						message: 'No se permiten puntos, comas ni espacios en este campo'
					}})
				})}
				error={error}
			/>
		</>
	)
}

function InputDob({register, error, isDependant}: RegisterInputs){
	return (
		<Input
			inputMode="text"
			type="date"
			label="Fecha de nacimiento"
			autoComplete="bday"
			{...register('dob', {
				validate: {
					validateAge: (dob) => validateUserAge(dob, isDependant),
					invalidDate: checkInvalidDate,
				},
				required: 'Debe introducir su fecha de nacimiento',
			})}
			error={error}
		/>
	)
}

function InputSex({ control, error, defaultValue }: RegisterSelect){
	return (
		<Controller
			control={control}
			name='sex'
			render={({field}) => (
				<Select
					label="Sexo según documento"
					placeholder='Sexo según documento'
					className='mt-6'
					error={error}
					defaultValue={defaultValue}
					onValueChange={(value) => field.onChange(value)}
					value={field.value}
					name={field.name}
					required
				>
					{sexOptions.map(({value, label}) => (
						<SelectItem value={value} key={value}>
							{label}
						</SelectItem>
					))}
				</Select>
			)}
		/>
	)
}

function InputWs<T extends FieldValues>({ error, control, classNameContainer, register, disabled, inputProps }: IWsInput<T>){
	const { ref, name } = register('ws' as Path<T>, {
		validate: checkValidWs
	})
	return (
		<Controller
			name={name}
			control={control}
			render={({ field }) => (
				<InputPhone
					ref={ref}
					onChange={field.onChange}
					value={field.value}
					classNameContainer={classNameContainer}
					error={error}
					disabled={disabled}
					disableDropdown={disabled}
					inputProps={inputProps}
				/>
			)}
		/>
	)
}
			
function InputEmail({register, error}: RegisterInputs){
	return <Input
		inputMode='email'
		type='email'
		autoComplete='email'
		label='Tu email personal'
		placeholder='ejemplo@gmail.com'
		{...register('email')}
		error={error}
	/>
}

function InputPronouns({ control, error, defaultValue }: RegisterSelect){
	return (
		<Controller
			control={control}
			name='pronouns'
			render={({field}) => (
				<Select
					label="Pronombre"
					placeholder='Pronombre'
					className='mt-6'
					error={error}
					defaultValue={defaultValue}
					onValueChange={(value) => field.onChange(value)}
					value={field.value}
					name={field.name}
					required
				>
					{pronounsOptions.map(({label, value}) => (
						<SelectItem value={value} key={label}>
							{label}
						</SelectItem>
					))}
				</Select>
			)}
		/>
	)
}

function InputNationality<T extends FieldValues>({ register, fieldError, watch, classNameContainer }: INationalityInput<T>) {
	return (
		<div className={cn(classNameContainer)}>
			<Combobox
				type="text"
				register={register('nationality' as Path<T>)}
				hasValue={!!watch('nationality' as Path<T>)}
				label="Nacionalidad"
				autocomplete="off"
				error={fieldError}
				inputmode="text"
				required
			>
				<ComboboxList persistSelection>
					{matchSorter(countriesListWithLabel, watch('nationality' as Path<T>), {
						keys: ['label'],
					}).map((element) => {
						return (
							<ComboboxOption
								key={element.value}
								value={element.label}
							>
								{element.label}
							</ComboboxOption>
						)
					})}
				</ComboboxList>
			</Combobox>
		</div>
	)
}

export {
	InputFirstname,
	InputLastname,
	InputDni,
	InputDob,
	InputSex,
	InputWs,
	InputEmail,
	InputChosenName,
	InputPronouns,
	InputNationality,
}