import Logo from '@/components/HomePage/Logo'
import { IClientState } from '@/config/clients'
import { Icon } from '@umahealth/occipital/client'
import { IHeader } from './IHeader'

export default function HeaderLogo({type, client} : { type: IHeader['type'], client?: IClientState|null}){
	if (type === 'back'){
		return <Icon
                		name="arrowBack"
                		size="size-5"
                		color="text-grey-6"
                		focusable={false}
			aria-hidden={true}
                		
		/>
	}
	return (
		<Logo
			client={client} 
			UMA_LOGO={{
				height: 48,
				width: 32,
				className: 'h-full'
			}}
			IOMA_LOGO={{
				className: 'h-full',
				height: 48,
				width: 32
			}}
			FARMATODO_LOGO={{
				className: 'h-full',
				height: 49,
				width: 119
			}}
		/>
	)
}